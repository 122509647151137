<template>
  <div id="welcome">
    <b-container>
      <b-row>
        <b-col class="text-center" style="color: white;">YINFU CUSTOM SUITS CO LTD</b-col>
      </b-row>
      <b-row>
        <b-col class="text-left">
          <label style="color: white;">Hello, {{ $store.state.common.user.name }}</label>
        </b-col>
        <b-col class="text-right" style="padding-bottom: 10px;">
          <b-link href="javascript:void(0);" :class="{active: $store.state.common.module==='design'}"
                  @click="handleClick('design')" style="color: white;">Design
          </b-link>
          <span style="color: white;padding: 0 6px;">|</span>
          <b-link href="javascript:void(0);" :class="{active: $store.state.common.module==='order'}"
                  @click="handleClick('order')" style="color: white;">Order
          </b-link>
          <template v-if="$store.state.common.user.roleId===1 || $store.state.common.user.roleId===2">
            <span style="color: white;padding: 0 6px;">|</span>
            <b-link href="javascript:void(0);" :class="{active: $store.state.common.module==='order-manage'}"
                    @click="handleClick('order-manage')" style="color: white;">Order Manage
            </b-link>
            <span style="color: white;padding: 0 6px;">|</span>
            <b-link href="javascript:void(0);" :class="{active: $store.state.common.module==='user-manage'}"
                    @click="handleClick('user-manage')" style="color: white;">User Manage
            </b-link>
          </template>
          <span style="color: white;padding: 0 6px;">|</span>
          <b-link href="javascript:void(0);" @click="handleLogout" style="color: white;">Logout
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <component :is="componentName"></component>
      </b-row>
      <b-row align-h="center">
        <span style="color:white;padding-top: 5px;">@Copyright. 2021</span>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  computed: {
    componentName: function () {
      return 'cpo-' + this.$store.state.common.module;
    }
  },
  methods: {
    handleClick(module) {
      this.$store.dispatch('common/changeModule', module);
    },
    handleLogout() {
      this.$bvModal.msgBoxConfirm('Are you sure want to log out?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirm',
        cancelTitle: 'Cancel'
      })
          .then(value => {
            if (value) {
              this.$store.dispatch('common/logout').then(() => {
                this.$router.push({path: '/login'});
              });
            }
          })
          .catch(err => {
            console.error(err);
          })
    }
  }
};
</script>

<style lang="scss">
#welcome {
  padding: 20px 30px 0;

  a {
    color: #2c3e50;

    &.active {
      font-weight: bold;
    }
  }
}
</style>